import React, { useState } from "react";
import Navbar from "../Navbar";
import ban1 from "../../assets/images/Apply Online.jpg";
import img3 from "../../assets/images/comp_3.svg";
import Footer from "../Home/Footer";
import "../../assets/styles/career.css";

const ApplyOnline = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    experience: "",
    state: "",
    country: "",
    mobile: "",
    job_applied: "",
    jobId: "6CkW45z62WqxCxEQkbrEvu",
    resume: null,
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.fullname) errors.fullname = "Full name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.experience) errors.experience = "Experience is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.country) errors.country = "Country is required";
    if (!formData.mobile) errors.mobile = "Mobile number is required";
    if (!formData.job_applied) errors.job_applied = "Job role is required";
    if (!formData.resume) errors.resume = "Resume file is required";
    else if (formData.resume.size > 3 * 1024 * 1024)
      errors.resume = "File size should be less than 3MB";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!validateForm()) {
      setError("Please fix the errors above.");
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch("https://tanisiinc.com/api/applyJob", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setSuccessMessage("Form submitted successfully.");
        setFormData({
          fullname: "",
          email: "",
          experience: "",
          state: "",
          country: "",
          mobile: "",
          job_applied: "",
          jobId: "6CkW45z62WqxCxEQkbrEvu",
          resume: null,
        });
      } else {
        setError("Form submission failed.");
      }
    } catch (err) {
      setError("Error: " + err.message);
    }
  };

  return (
    <div>
      <Navbar />
      <div style={{ height: "4rem" }}></div>
      <img loading="lazy" src={ban1} className="image_3"></img>
      <img loading="lazy" src={img3} style={{ width: "100%" }} className="image_3"></img>
      <div style={{ background: "black", color: "white" }} className="apply">
        <div className="apply-main">
          <span className="apply-head">Apply Online</span>
          <form onSubmit={handleSubmit}>
            <div className="apply-form-field">
              <label>
                Full Name
                <input
                  type="text"
                  name="fullname"
                  placeholder="Name"
                  value={formData.fullname}
                  onChange={handleChange}
                />
                {formErrors.fullname && <span className="error-message">{formErrors.fullname}</span>}
              </label>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && <span className="error-message">{formErrors.email}</span>}
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                Job Role
                <input
                  type="text"
                  name="job_applied"
                  placeholder="Job Role"
                  value={formData.job_applied}
                  onChange={handleChange}
                />
                {formErrors.job_applied && <span className="error-message">{formErrors.job_applied}</span>}
              </label>
              <label>
                Experience
                <input
                  type="number"
                  name="experience"
                  placeholder="Experience"
                  value={formData.experience}
                  onChange={handleChange}
                />
                {formErrors.experience && <span className="error-message">{formErrors.experience}</span>}
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                State
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                />
                {formErrors.state && <span className="error-message">{formErrors.state}</span>}
              </label>
              <label>
                Country
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                />
                {formErrors.country && <span className="error-message">{formErrors.country}</span>}
              </label>
            </div>
            <div className="apply-form-field" style={{ width: "50%" }}>
              <label>
                Phone number (include country code)
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {formErrors.mobile && <span className="error-message">{formErrors.mobile}</span>}
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                Upload file (maximum file size: 3mb)
                <input
                  type="file"
                  name="resume"
                  accept="application/pdf"
                  onChange={handleChange}
                  className="custom-choose"
                />
                {formErrors.resume && <span className="error-message">{formErrors.resume}</span>}
              </label>
            </div>
            <button type="submit" className="submit-form-button">
              Submit
            </button>
            {error && <p className="error-text" style={{ color: "red" }}>{error}</p>}
            {successMessage && <p className="success-text" style={{ color: "green" }}>{successMessage}</p>}
          </form>
        </div>
      </div>
      <img loading="lazy" src={img3} style={{ width: "100%" }} className="image_3"></img>
      <Footer />
    </div>
  );
};

export default ApplyOnline;
