import Navbar from "../Navbar";
import img3 from "../../assets/images/comp_3.svg";
import Footer from "../Home/Footer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/career.css";

const JobDescription = () => {
  const { jobId } = useParams();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null); // For success/error message
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`https://tanisiinc.com/api/jobs?jobId=${jobId}`);
        setJobs(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJob();
  }, [jobId]);

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    experience: "",
    state: "",
    country: "",
    mobile: "",
    job_applied: "",
    jobId: jobId,
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Form Validations
    if (!formData.fullname || !formData.email || !formData.job_applied || !formData.resume) {
      setMessage({ text: "Please fill out all required fields.", type: "error" });
      return;
    }
    if (!formData.email.includes("@")) {
      setMessage({ text: "Please enter a valid email.", type: "error" });
      return;
    }
    if (formData.resume && formData.resume.size > 3 * 1024 * 1024) { // 3MB limit
      setMessage({ text: "Resume file size should be less than 3MB.", type: "error" });
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch("https://tanisiinc.com/api/applyJob", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setMessage({ text: "Form submitted successfully!", type: "success" });
        setFormData({
          fullname: "",
          email: "",
          experience: "",
          state: "",
          country: "",
          mobile: "",
          job_applied: "",
          jobId: jobId,
          resume: null,
        });
      } else {
        setMessage({ text: "Form submission failed. Please try again.", type: "error" });
      }
    } catch (error) {
      setMessage({ text: "An error occurred. Please try again later.", type: "error" });
    }
  };

  return (
    <div>
      <Navbar />
      {jobs.map((job) => (
        <div key={job.jobId}>
          <div className="top-jd">
            <div style={{ display: "grid" }}>
              <div style={{ fontSize: "35px" }}>{job.jobTitle}</div>
              <div style={{ display: "flex", color: "#888888" }}>
                <div>{job.company} | &nbsp;</div>
                <div>{job.experience} yrs | &nbsp;</div>
                <div>{job.jobType} | &nbsp;</div>
                <div>{job.location}</div>
              </div>
            </div>
          </div>
          <div className='bottom-jd'>
            <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
          </div>
        </div>
      ))}
      <div id="section2" style={{ background: "black", color: "white" }} className="apply">
        <div className="apply-main">
          <span className="apply-head">Apply Online</span>
          <br /><br />
          <span className="apply-body">
            To apply, you may email us your resume as a Word or RTF attachment. Or, use the form below and fill in your contact information, and copy and paste your resume into the space provided below:
          </span>
          <br /><br /><br /><br />
          {message && (
            <div style={{ color: message.type === "error" ? "red" : "green", marginBottom: "1em" }}>
              {message.text}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="apply-form-field">
              <label>
                Full Name
                <input type="text" name="fullname" placeholder="Name" onChange={handleChange} value={formData.fullname} />
              </label>
              <label>
                Email
                <input type="text" name="email" placeholder="Email" onChange={handleChange} value={formData.email} />
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                Job Role
                <input type="text" name="job_applied" placeholder="Job Role" onChange={handleChange} value={formData.job_applied} />
              </label>
              <label>
                Experience
                <input type="number" name="experience" placeholder="Experience" onChange={handleChange} value={formData.experience} />
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                State
                <input type="text" name="state" placeholder="State" onChange={handleChange} value={formData.state} />
              </label>
              <label>
                Country
                <input type="text" name="country" placeholder="Country" onChange={handleChange} value={formData.country} />
              </label>
            </div>
            <div className="apply-form-field" style={{ width: "50%" }}>
              <label>
                Phone number
                <input type="number" name="mobile" placeholder="Mobile" onChange={handleChange} value={formData.mobile} />
              </label>
            </div>
            <div className="apply-form-field">
              <label>
                Upload file
                <input type="file" name="resume" accept="application/pdf" onChange={handleChange} className="custom-choose" />
              </label>
              <button type="submit" className="submit-form-button">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <img loading="lazy" src={img3} style={{ width: "100%" }} className="image_3" alt="background" />
      <Footer />
    </div>
  );
};

export default JobDescription;
